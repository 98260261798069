<template>
  <div class="current_plan">
    <p class="plan_p" v-for="(item, index) in textPlan" :key="index">{{item}}</p>
  </div>
</template>

<script>
  export default {
    data() {
      return {}
    },
    props: {
      textPlan: {
        type: Array,
        default: () => ([])
      }
    }
  }
</script>

<style scoped lang="scss">
  .current_plan{
    background-color: #fff;
    padding: 0.85rem 1.05rem 0.75rem;
    color: #999;
    font-size: 0.18rem;
    .plan_p{
      line-height: 0.36rem;
    }
  }
</style>
