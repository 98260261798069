<template>
  <div class="operation_diagram_con">
    <ul class="oper_ul" @click="changeTab">
      <li
        class="oper_single"
        :class="tabKey === '1' ? 'oper_single_active' : ''"
        data-key="1"
      >
        {{$t('casesDetail.casesChildren.textPlanChildren.dqfa')}}
        <span
          class="o_icon"
          data-key="1"
        ></span>
      </li>
      <li
        class="oper_single"
        :class="tabKey === '2' ? 'oper_single_active' : ''"
        data-key="2"
      >
        {{$t('casesDetail.casesChildren.textPlanChildren.lsfa')}}
        <span
          class="o_icon"
          data-key="2"
        ></span>
      </li>
    </ul>
    <current-plan v-show="tabKey === '1'" :textPlan="textPlan"/>
    <his-plan v-show="tabKey === '2'"/>
  </div>
</template>

<script>
  import HisPlan from './hisPlan';
  import CurrentPlan from './currentPlan';
  import {detailCurePlan} from 'common/api/cases';
  import {splitText} from 'common/js/util';
  export default {
    data() {
      return {
        tabKey: sessionStorage.getItem('textPlanKey') || '1',
        textPlan: ''
      }
    },
    created() {
      let id = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      sessionStorage.setItem('curePlanId', id);
      localStorage.removeItem('curePlanId');
      sessionStorage.removeItem('planMsg');
      if (id) {
        this.curePlanId = id;
        detailCurePlan(id).then(obj => {
          this.textPlan = splitText(obj.textPlan);
          sessionStorage.setItem('planMsg', JSON.stringify(obj));
          this.$emit('getPlanMsg', obj);
        });
      }
    },
    methods: {
      changeTab(ev) {
        const target = ev.target;
        const tabKey = target.getAttribute('data-key');
        if(tabKey) {
          sessionStorage.setItem('textPlanKey', tabKey);
          this.tabKey = tabKey;
          this.$emit('changeTabKey', tabKey);
        }
      }
    },
    components: {
      HisPlan,
      CurrentPlan
    }
  }
</script>

<style scoped lang="scss">
  .operation_diagram_con {
    color: $main_theme_color_333;
    font-size: 0.16rem;
    .oper_tit {
      color: $main_theme_color_333;
      font-size: 0.24rem;
      margin-bottom: 0.2rem;
    }
    .oper_ul {
      display: flex;
      align-items: center;
      color: $main_theme_color_333;
      font-size: 0.18rem;
      border-bottom: #eee;
      span {
        display: inline-block;
      }
      .oper_single {
        width: 1.48rem;
        height: 0.46rem;
        line-height: 0.46rem;
        text-align: center;
        border-top-left-radius: 0.06rem;
        border-top-right-radius: 0.06rem;
        margin-right: 0.02rem;
        background-color: #fff;
        position: relative;
        cursor: pointer;
        .o_icon {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 0.16rem;
          height: 0.08rem;
          background-size: 100% 100%;
          background-image: url("/static/images/nav_sp.png");
        }
      }
      .oper_single_active {
        background-color: $main_theme_color;
        color: #fff;
      }
    }
  }
</style>
